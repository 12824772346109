import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  TextInputComponent,
  TextInputComponentDefinition,
} from './TextInputComponent';
import {ComponentDefinition} from './TextInputDefinition';

type Kind = TextInputComponentDefinition['component'];

type Props = TextInputComponentDefinition['props'];

export const TextInputModule: Module<Kind, Props> = {
  isContent(value): value is TextInputComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  isLayout(value): value is TextInputComponentDefinition {
    return false;
  },
  tryRender(definition) {
    if (TextInputModule.isContent(definition)) {
      return React.createElement(TextInputComponent, definition);
    } else {
      return NextModule;
    }
  },
};
