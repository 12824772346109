export {TextInputComponent} from './TextInputComponent';
export type {
  ITextInputProps,
  TextInputComponentDefinition,
} from './TextInputComponent';
export {ComponentDefinition} from './TextInputDefinition';
export type {SchemaType} from './TextInputDefinition';
export {TextInputPreviewComponent} from './TextInputPreviewComponent';
export {textValidationOptionGroupSchema} from './helpers/react-hook-form/typebox-helpers';

export const schema = ComponentDefinition.schema;

import {Registry} from '@backstage-components/base';
import {ComponentDefinition} from './TextInputDefinition';
import {TextInputModule} from './TextInputModule';
Registry.register(ComponentDefinition, TextInputModule);
